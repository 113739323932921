import * as R from 'ramda';

interface IConfig {
  accessTokenCookie: string;
  azureLoginEnabled: boolean;
  clientId: string;
  clientSecret: string;
  gkApiUrl: string;
  gkFileUploadsUrl: string;
  marketingSiteUrl: string;
  recaptchaSiteKey: string;
  referralsApiUrl: string;
  storeUrl: string;
  supportSiteUrl: string;
  timelinesUrl: string;
  validRedirectHostnames: string[];
  webSocketProtocol: string;
  documentDomain: string;
  chargebee: {
    site: string;
    publishableKey: string;
  };
  paypalExpressCheckoutURL: string;
  gitLensURLs: string[];
  ssoEnabled: boolean;
  portalIdHbspt: string;
  formIdHbspt: string;
  cliRedirectUrl: string;
  scimEnabled: boolean;
  prosperStackClientId: string;
  prosperStackEnabled: boolean;
  goldenTicketEnabled: boolean;
  accountInfoBannerMessage: Array<{text: string; startDate?: string; endDate?: string}>;
  referralRemovalDate: string;
  dotDevBaseUrl: string;
  shouldRedirectTrafficToDotDev: boolean;
}

let config: Readonly<IConfig>;

if (process.env.ACCT_TEST) {
  config = require('../config/config-test.json');
} else if (process.env.GK_ACCT_RUN_MODE !== 'local') {
  config = require('../config/config.json');
} else {
  config = require('../config/config-template.json');
  try {
    const customConfig = require('../config/config.json');
    config = R.mergeDeepRight(config, customConfig);
  } catch (e) { /* no custom config */ }
}

export default config;
