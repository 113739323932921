import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { defineGkElement, PricingCalculator } from '@gitkraken/shared-web-components/pricing';
import './assets';

import './index.css';
import './utils/iconUtils';

import config from './config';

// shared web components need to be registered once
// register the class name of the component to be used
defineGkElement(PricingCalculator);

try {
  document.domain = config.documentDomain;
} catch (e) { /* you are probably inside vscode */ }

const App = React.lazy(() => import('./App'));

const MinimalFallback = () => (
  <div />
);

ReactDOM.render(
  <React.Suspense fallback={<MinimalFallback />}>
    <App />
  </React.Suspense>,
  document.getElementById('root') as HTMLElement,
);
