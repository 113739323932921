
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';

import {
  faCheck as faCheckRegular,
  faCircle as faCircleRegular,
  faCode as faCodeRegular,
  faColumns as faColumnsRegular,
  faCopy as faCopyRegular,
  faDotCircle as faDotCircleRegular,
  faGift as faGiftRegular,
  faKey as faKeyRegular,
  faLink as faLinkRegular,
  faPlusSquare as faPlusSquareRegular,
  faRocket as faRocketRegular,
  faShieldCheck as faShieldCheckRegular,
  faSignOut as faSignOutRegular,
  faSitemap as faSitemapRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faSyncAlt as faSyncAltRegualr,
  faTools as faToolsRegular,
  faUser as faUserRegular,
  faUsers as faUsersRegular,
  faWrench as faWrenchRegular,
  faFileCertificate as faFileCertificateRegular,
  faQuestionCircle as faQuestionCircleRegular,
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular,
  faMinusSquare as faMinusSquareRegular,
  faCreditCard as faCreditCardRegular,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faArrowToBottom as faArrowToBottomSolid,
  faArrowLeft as faArrowLeftSolid,
  faCaretDown as faCaretDownSolid,
  faCaretUp as faCaretUpSolid,
  faChevronCircleLeft as faChevronCircleLeftSolid,
  faChevronLeft as faChevronLeftSolid,
  faEnvelope as faEnvelopeSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faHeart as faHeartSolid,
  faInfoCircle as faInfoCircleSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faSignOutAlt as faSignOutAltSolid,
  faStar as faStarSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faTimes as faTimes,
  faExternalLink,
  faAngleRight,
  faAngleDown,
  faUniversity as faUniversitySolid,
  faBars,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBitbucket,
  faFacebook,
  faGithub,
  faGitkraken,
  faGitlab,
  faGoogle,
  faLinkedin,
  faTwitter,
  faPaypal,
  faApplePay,
  faAmazonPay,
  faApple,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';

faLibrary.add(
  faArrowToBottomSolid,
  faArrowLeftSolid,
  faBitbucket,
  faCaretDownSolid,
  faCaretUpSolid,
  faCheckRegular,
  faChevronCircleLeftSolid,
  faChevronLeftSolid,
  faBars,
  faCircleRegular,
  faCodeRegular,
  faColumnsRegular,
  faCopyRegular,
  faDotCircleRegular,
  faExclamationCircleSolid,
  faGiftRegular,
  faHeartSolid,
  faKeyRegular,
  faEnvelopeSolid,
  faGiftRegular,
  faInfoCircleSolid,
  faLinkRegular,
  faPlusSquareRegular,
  faRocketRegular,
  faShieldCheckRegular,
  faSignOutAltSolid,
  faSignOutRegular,
  faSitemapRegular,
  faSpinnerThirdRegular,
  faStarSolid,
  faSyncAltRegualr,
  faTimes,
  faToolsRegular,
  faUserRegular,
  faUsersRegular,
  faFacebook,
  faGithub,
  faGitkraken,
  faGitlab,
  faGoogle,
  faLinkedin,
  faTwitter,
  faWrenchRegular,
  faExclamationTriangleSolid,
  faExternalLink,
  faFileCertificateRegular,
  faAngleRight,
  faAngleDown,
  faQuestionCircleRegular,
  faQuestionCircleSolid,
  faEyeRegular,
  faEyeSlashRegular,
  faMinusSquareRegular,
  faCreditCardRegular,
  faUniversitySolid,
  faPaypal,
  faApplePay,
  faAmazonPay,
  faApple,
  faWindows,
);
